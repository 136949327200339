








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'HeaderLogo'
})
export default class HeaderLogo extends Vue {
  @Prop(String) readonly name: string | undefined
}
